
import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class extends Vue {
  @Prop() error!: any
  errorMask:string = ''
  mounted () {
    this.errorMask = this.error?.statusCode.toString().replace(/[0-9][0-9]/gm, '')
  }
}
