import { Severity } from '@sentry/types'

export const state = () => ({
  news: []
})

export const getters = {
  getList: (state) => {
    return state.news
  }
}

export const mutations = {
  setList (state, news) {
    state.news.splice(0, state.news.length, ...news)
  }
}

export const actions = {
  setList ({ commit }) {
    return this.$static.getPageList('news', 6)
      .then((data) => {
        commit('setList', Object.freeze(data))
      }).catch(() => {
        this.$sentry.captureMessage('Ошибка при получении списка новостей', Severity.Warning)
      })
  }
}
