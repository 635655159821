export const state = () => ({
  unpaid: [],
  currentIdentity: {},
  identities: []
})

export const getters = {
  geIdentities: (state) => {
    return state.identities
  },
  getUnpaid: (state) => {
    return state.unpaid
  },
  getCurrentIdentity: (state) => {
    return state.currentIdentity
  }
}

export const mutations = {
  add (state, identities) {
    state.identities.push(...(identities || []))
  },
  // добавляем неоплаченные штрафы
  toggleUnpaid (state, accrual) {
    if (state.unpaid.some(i => i.uin === accrual.uin)) {
      state.unpaid.splice(
        state.unpaid.findIndex(item => item.uin === accrual.uin),
        1
      )
    } else {
      state.unpaid.unshift(accrual)
    }
  },
  cleanUnpaid (state) {
    state.unpaid = []
  },
  // ставим текущий документ для оплаты
  setCurrent (state, identity) {
    state.currentIdentity = identity
  },
  // добавляем массив документов
  setIdentities (state, identities) {
    state.identities = identities
  }
}
