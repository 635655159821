
export const state = () => ({
  stateScroll: { scroll: false }
})

export const getters = {
  getScroll: (state) => {
    return state.stateScroll.scroll
  }
}

export const mutations = {
  setScroll (state, newScroll) {
    state.stateScroll.scroll = newScroll
  }
}
