import {Context} from '@nuxt/types'
import {Inject} from '@nuxt/types/app'

export enum YMGoals {
    AUTOPAYMENT_CONNECT_CLICK = 'click-connect-auto-payment',
    AUTOPAYMENT_CONNECT_SUCCESS = 'success-connect-auto-payment'
}

declare global {
    interface Window {
        ym: any
    }
}

export interface YMPlugin {
    reachGoal(goal: YMGoals | string): void
}

declare module '@nuxt/types' {
    interface Context {
        $yandexMetrics: YMPlugin
    }

    interface NuxtAppOptions {
        $yandexMetrics: YMPlugin
    }
}

declare module 'vue/types/vue' {
    interface Vue {
        $yandexMetrics: YMPlugin
    }
}

const onLoad = (callback, delay = 1) => {
    // missed document the load event, run now
    if (document.readyState === 'complete') {
        setTimeout(() => callback(), delay)
    } else {
        window.addEventListener('load', function () {
            setTimeout(() => callback(), delay)
        })
    }
}

export default (_ctx: Context, inject: Inject) => {
    const options = JSON.parse('{"ym_id":83947918}')
    const YM_ID = options.ym_id

    if (!YM_ID) {
        return false
    }
    const callYM = (target, ...args) => {
        try {
            window.ym(YM_ID, target, ...args)
        } catch (err) {
            console.error(err)
        }
    }
    /**
     * Инициализация метрики
     */
    onLoad(() => {
        /* eslint-disable */
        (function (m, e, t, r, i, k, a) {
            m[i] = m[i] || function () {
                (m[i].a = m[i].a || []).push(arguments)
            }
            m[i].l = Number(new Date())
            // @ts-ignore
            k = e.createElement(t), a = e.getElementsByTagName(t)[0], k.async = 1, k.src = r, a.parentNode.insertBefore(k, a)
        })(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym')
        /* eslint-enable */

        callYM('init', {
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: true
        })
    })

    /**
     * Метод отправки reachGoal в яндекс метрику
     * @param {string} goal - идентификатор цели
     */
    const reachGoal = (goal: YMGoals | string): void => {
        if (!goal) {
            console.error('[Analytics]: goal is required')
        }
        callYM('reachGoal', goal)
    }

    inject('yandexMetrics', {
        reachGoal
    })
}
